<template>
  <div class="login">
    <div class="login-form" @keyup.enter="login" align="center">
      <div class="login-title">
        <h1>聚禾美</h1>
      </div>
      <input type="username" name="username" v-model="form.login_name" placeholder="用户名">
      <input type="password" name="password" v-model="form.login_pwd" placeholder="密码">
      <button @click="login">登录</button>
    </div>
  </div>
</template>
<script>
import { authApi } from '@/api'
import { mapActions } from 'vuex'
import { USER_SIGNIN, USER_SIGNOUT } from '@/store/user'
import { MENU_INIT } from '@/store/menu'

export default {
  data: () => ({
    form: {
      login_name: '',
      login_pwd: ''
    }
  }),

  methods: {
    ...mapActions([USER_SIGNIN, USER_SIGNOUT, MENU_INIT]),

    async login() {
      if(!this.form.login_name || !this.form.login_pwd) {
        return this.$message.error('缺少必要信息')
      }
      let loading = this.$loading()
      try {
        let data = (await authApi.login(this.form)).data
        if (data.code === 0) {
          this.USER_SIGNIN(data.data)
          this.$cookies.set('token', data.data.token)
          let menus = (await authApi.getEmployeeMenu()).data
          this.MENU_INIT(menus.data)
          this.$message.success('登录成功')
          const {width} = document.body.getBoundingClientRect()
          let router = {}
          if(width > 480) {
            router = {
              name: "index"
            }
          } else {
            router = {
              name: "mobileOrderManage",
              query: {
                active: 'home'
              }
            }
          }
          this.$router.push(router)
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    }
  },

  mounted() {
    this.USER_SIGNOUT()
  }
}
</script>

<style lang="less" scoped>
.publicInput{
  display: block;
  width: 400px;
  height: 40px;
  margin: 0 0 15px;
  border-radius: 20px;
  text-align: center;
}
.login{
  width: 100%;
  height: 100%;
  background: #324057;
  .login-title{
    margin-bottom: 60px;
    h1{
      color: #fff;
      font-size: 50px;
      text-align: center;
    }
  }
  .login-form{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    input{
      .publicInput;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
      padding: 0 12px;
    }
    button{
      .publicInput;
      background: #fff;
      color: #324057;
      border: none;
    }
  }
  .login-desc{
    p{
      text-align: center;
      font-size: 12px;
      color: #eee;
    }
  }
}
@media screen and (max-width: 480px) {
  .publicInput{
    width: 360px;
    height: 40px;
  }
  .login{
    .login-title{
      margin-bottom: 60px;
      h1{
        font-size: 36px;
      }
    }
    .login-form{
      input{
        .publicInput;
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
        padding: 0 12px;
      }
      button{
        .publicInput;
        background: #fff;
        color: #324057;
        border: none;
      }
    }
    .login-desc{
      p{
        text-align: center;
        font-size: 12px;
        color: #eee;
      }
    }
  }
};
</style>
